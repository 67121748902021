import stepTypes from 'components/Booking-new/modules/Steps/types';

const formatDataType = data => ({
  userData: {
    [stepTypes.personalData]: {
      ...data,
    },
  },
});

export default formatDataType;
