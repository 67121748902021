const UAParser = require('ua-parser-js');
const { getBrowserName, getDeviceVendor, getIsMobile, getOSName } = require('./userAgent');

const UNDEFINED_OPTIMIZELY_USER_ATTRIBUTE_PLACEHOLDER = 'Other';

function getOptimizelyUserAttributes(userAgentString, utms) {
  const userAgent = new UAParser(userAgentString);

  return {
    ...utms,
    device: getDeviceVendor(userAgent) || UNDEFINED_OPTIMIZELY_USER_ATTRIBUTE_PLACEHOLDER,
    os: getOSName(userAgent) || UNDEFINED_OPTIMIZELY_USER_ATTRIBUTE_PLACEHOLDER,
    mobile: userAgent.getDevice().type ? getIsMobile(userAgent) : UNDEFINED_OPTIMIZELY_USER_ATTRIBUTE_PLACEHOLDER,
    browser: getBrowserName(userAgent) || UNDEFINED_OPTIMIZELY_USER_ATTRIBUTE_PLACEHOLDER,
  };
}

module.exports = getOptimizelyUserAttributes;
