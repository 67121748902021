function getDeviceVendor(userAgent) {
  return userAgent.getDevice().vendor;
}

function getOSName(userAgent) {
  return userAgent.getOS().name;
}

function getIsMobile(userAgent) {
  return userAgent.getDevice().type === 'mobile';
}

function getBrowserName(userAgent) {
  return userAgent.getBrowser().name;
}

module.exports = { getDeviceVendor, getOSName, getIsMobile, getBrowserName };
