import splitUrlExperiments from '../../split-url-redirects.json';

/**
 * Remove the cookies for the split url experiments which are no more in use
 */
export default function removeSplitUrlExperimentCookies() {
  const experimentCookies = document.cookie.split('; ').filter(row => row.startsWith('spUExp-'));
  if (!experimentCookies) return;

  experimentCookies.forEach(cookie => {
    const cookieName = cookie.split('=')[0];
    const usedExperiment = splitUrlExperiments.find(exp => `spUExp-${exp.name}` === cookieName);
    // if the experiment is still being used, don't delete its query and just skip it
    if (usedExperiment) return;
    // if the experiment is not being used anymore, delete its cookie
    document.cookie = `${cookieName}=; Max-Age=0; path=/; domain=${location.host}`;
  });
}
