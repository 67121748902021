import { IS_PREVIEW } from 'constants/global';
import { getCookie } from '../cookies';

const OPTIMIZELY_USER_ID = 'anonymous-id';

export default function getOptimizelyUserId() {
  // building
  if (typeof window === 'undefined') return null;

  // ID should be created on the edge
  let id = getCookie(OPTIMIZELY_USER_ID);

  // if there is no ID from the Edge, it means we are on dev
  // in this case we can just use 'dev' id
  // if you want to make new decision making process
  // you should override this ID
  if (IS_PREVIEW)
    // we don't really need to save this to cookie
    id = 'dev_user';

  return id;
}
