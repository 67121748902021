import React, { createContext, useEffect, useMemo, useState } from 'react';
import { setLocalStorage } from '../utils/setLocalStorage';

export const BASContext = createContext(null);

export const BASProvider = ({ children }) => {
  const [timestamp, setTimestamp] = useState('');
  const [uuid, setUuid] = useState(null);

  useEffect(() => {
    setTimestamp(Date.now().toString());
    let userUUID = localStorage.getItem('user-anom-id');

    // create unique anonymous id , if user-ai does not exist on localStorage yet
    if (!userUUID)
      if (crypto.randomUUID) {
        userUUID = crypto.randomUUID();
        setLocalStorage('user-anom-id', userUUID);
      } else {
        console.error('crypto.randomUUID() is not supported in this browser.');
      }
    setUuid(userUUID);
  }, []);

  // store the URL in local storage
  const storeVisit = finalUrl => {
    let isAlreadyStored = false;
    for (let i = 1; localStorage.getItem(`visit${i}`); i++)
      if (localStorage.getItem(`visit${i}`) === finalUrl) {
        isAlreadyStored = true;
        break;
      }

    // If not stored, add it
    if (!isAlreadyStored) {
      const getNextKey = prefix => {
        let i = 1;
        while (localStorage.getItem(`${prefix}${i}`)) i++;
        return `${prefix}${i}`;
      };

      const newKey = getNextKey('visit');

      setLocalStorage(newKey, finalUrl);
    }
  };

  const buildQueryString = useMemo(() => {
    const allParams = new URLSearchParams(window.location.search);
    if (timestamp) allParams.set('ts', timestamp);
    const pageReferrer = document.referrer;
    if (pageReferrer) allParams.set('pr', encodeURIComponent(pageReferrer));
    // urlString to add to links
    return allParams.toString();
  }, [timestamp, uuid]);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname.replace(/\/+$/, ''); // Remove any trailing slashes
    const allParams = new URLSearchParams(window.location.search);

    if (timestamp) {
      allParams.set('ts', timestamp);
      const pageReferrer = document.referrer;
      if (pageReferrer) allParams.set('pr', encodeURIComponent(pageReferrer));
      const paramsString = allParams.toString();
      const finalUrl = `${window.location.origin}${pathname}/?${paramsString}`;

      if (paramsString.includes('ts=')) storeVisit(finalUrl);
    }
  }, [timestamp, uuid]);

  return <BASContext.Provider value={buildQueryString}>{children}</BASContext.Provider>;
};
