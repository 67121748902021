import { getUtmsFromUrl } from 'utils/analytics';
import getSharedOptimizelyUserAttributes from '../../../shared/base/utils/optimizely/getOptimizelyUserAttributes';

export default function getOptimizelyUserAttributes() {
  if (typeof window === 'undefined') return {};

  const userAgentString = window.navigator.userAgent;

  const utms = getUtmsFromUrl();

  const attributes = getSharedOptimizelyUserAttributes(userAgentString, utms);

  return {
    ...attributes,
    // Optimizely bot filtering not needed client-side (learn more at https://docs.developers.optimizely.com/full-stack/docs/manage-bot-filtering)
    // $opt_user_agent: userAgentString,
  };
}
