const countries = {
  'de-DE': {
    name: 'Deutschland',
    contentfulName: 'Deutschland',
    lang: 'de-DE',
    shortName: 'DE',
    hrefLang: 'de-DE',
    currencySymbol: '€',
    domain: 'https://drsmile.de',
    domainExtension: '.de',
    gatsbyTestName: 'https://testdrsmilede-{BRANCH_NAME}.gtsb.io/',
  },
  'de-AT': {
    name: 'Österreich',
    contentfulName: 'Österreich',
    lang: 'de-AT',
    shortName: 'AT',
    hrefLang: 'de-AT',
    currencySymbol: '€',
    domain: 'https://drsmile.at',
    domainExtension: '.at',
    gatsbyTestName: 'https://testdrsmileat-{BRANCH_NAME}.gtsb.io/',
  },
  'de-CH': {
    name: 'Switzerland',
    contentfulName: 'Switzerland',
    lang: 'de-CH',
    shortName: 'CH',
    hrefLang: 'de-CH',
    currencySymbol: 'CHF',
    domain: 'https://drsmile.ch',
    domainExtension: '.ch',
    gatsbyTestName: 'https://testdrsmilech-{BRANCH_NAME}.gtsb.io/',
  },
  es: {
    name: 'España',
    contentfulName: 'Spain',
    lang: 'es',
    shortName: 'ES',
    hrefLang: 'es',
    currencySymbol: '€',
    domain: 'https://drsmile.es',
    domainExtension: '.es',
    gatsbyTestName: 'https://testdrsmilees-{BRANCH_NAME}.gtsb.io/',
  },
  fr: {
    name: 'France',
    contentfulName: 'France',
    lang: 'fr',
    shortName: 'FR',
    hrefLang: 'fr',
    currencySymbol: '€',
    domain: 'https://drsmile.fr',
    domainExtension: '.fr',
    gatsbyTestName: 'https://testdrsmilefr-{BRANCH_NAME}.gtsb.io/',
  },
  pl: {
    name: 'Polska',
    contentfulName: 'Poland',
    lang: 'pl',
    shortName: 'PL',
    hrefLang: 'pl',
    currencySymbol: 'zł',
    domain: 'https://drsmile.pl',
    domainExtension: '.pl',
    gatsbyTestName: 'https://testdrsmilepl-{BRANCH_NAME}.gtsb.io/',
  },
  it: {
    name: 'Italia',
    contentfulName: 'Italy',
    lang: 'it',
    shortName: 'IT',
    hrefLang: 'it',
    currencySymbol: '€',
    domain: 'https://dr-smile.it',
    domainExtension: '.it',
    gatsbyTestName: 'https://testdrsmileit-{BRANCH_NAME}.gtsb.io/',
  },
  se: {
    name: 'Sweden',
    contentfulName: 'Sweden',
    lang: 'sv',
    shortName: 'SE',
    hrefLang: 'sv-SE',
    currencySymbol: 'kr',
    domain: 'https://drsmile.se',
    domainExtension: '.se',
    gatsbyTestName: 'https://testdrsmilese-{BRANCH_NAME}.gtsb.io/',
  },
  'en-GB': {
    name: 'United Kingdom',
    contentfulName: 'United Kingdom',
    lang: 'en-GB',
    shortName: 'GB',
    hrefLang: 'en-GB',
    currencySymbol: '£',
    domain: 'https://drsmile.co.uk',
    domainExtension: '.co.uk',
    gatsbyTestName: 'https://testdrsmilegb-{BRANCH_NAME}.gtsb.io/',
  },
  nl: {
    name: 'Dutch',
    contentfulName: 'Netherlands',
    lang: 'nl',
    shortName: 'NL',
    hrefLang: 'nl',
    currencySymbol: '€',
    domain: 'https://drsmile.nl',
    domainExtension: '.nl',
    gatsbyTestName: 'https://testdrsmilenl-{BRANCH_NAME}.gtsb.io/',
  },
  'pt-PT': {
    name: 'Portugal',
    contentfulName: 'Portugal',
    lang: 'pt-PT',
    shortName: 'PT',
    hrefLang: 'pt-PT',
    currencySymbol: '€',
    domain: 'https://dr-smile.pt',
    domainExtension: '.pt',
    gatsbyTestName: 'https://testdrsmilept-{BRANCH_NAME}.gtsb.io/',
  },
};

module.exports = countries;
