/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';

import TagManager from 'react-gtm-module';

import { saveUtms } from 'utils/analytics';
import countries from 'data/countries';
import { BookingProvider } from 'components/Booking-new/providers/booking';

import WrapWithProvider from 'utils/optimizely/WrapWithOptimizelyProvider';
import { initializeEdgeTag } from './src/utils/edgetag';
import removeSplitUrlExperimentCookies from './src/utils/removeSplitUrlExperimentCookies';
import { BASProvider } from './src/providers/basContext';

const gtmId = process.env.GATSBY_GOOGLE_TAGMANAGER_ID;
const gtmAuth = process.env.GATSBY_GOOGLE_TAGMANAGER_AUTH; // this env cannot be added in the production Gatsby Cloud
const gtmPreview = process.env.GATSBY_GOOGLE_TAGMANAGER_PREVIEW; // this env cannot be added in the production Gatsby Cloud

const gtmConfig = {
  gtmId,
  ...(gtmAuth && {
    auth: gtmAuth,
  }),
  ...(gtmPreview && {
    preview: gtmPreview,
  }),
};

export const onClientEntry = () => {
  TagManager.initialize(gtmConfig);

  initializeEdgeTag();

  saveUtms();

  localStorage.setItem('userEnteredBy', window.location.href);

  removeSplitUrlExperimentCookies();

  if (document.cookie.split('; ').find(row => row.startsWith('nf_country'))) return;

  const countryCode = countries[process.env.GATSBY_COUNTRY_CODE].shortName.toUpperCase();
  document.cookie = `nf_country=${countryCode};  expires=Fri, 31 Dec 9999 23:59:59 GMT`;
};

export const onInitialClientRender = () => {
  window.scrollTo(0, 0);
};

export const shouldUpdateScroll = () => {
  return [0, 0];
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const element = location?.hash ? document.querySelector(location.hash) : null;

  // We need this to get the `previousPath` on Layout component and pass to GTM
  window.previousPath = prevLocation?.href;

  if (element) element.scrollIntoView();
  else window.scrollTo(0, 0);

  if (!prevLocation?.pathname) return;

  if (location.pathname.includes('/booking')) sessionStorage.removeItem('booking');

  return null;
};

export const wrapRootElement = ({ element }) => {
  let providerElement = <BookingProvider>{element}</BookingProvider>;

  if (process.env.GATSBY_PERSIST_UTM === 'true') providerElement = <BASProvider>{providerElement}</BASProvider>;

  if (process.env.GATSBY_OPTIMIZELY_SDK) providerElement = <WrapWithProvider element={providerElement} />;

  return providerElement;
};
