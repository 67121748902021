import isObjectNotEmpty from 'utils/isObjectNotEmpty';
import isSSR from 'mui/base/utils/isSSR';
import getUtmsFromUrlSearchParams from '../../shared/base/utils/getUtmsFromUrlSearchParams';
import UTMS from '../../shared/assets/constants/utms';
import { setLocalStorage } from './setLocalStorage';

const SEARCH_ENGINE_REFERRERS_REGEX =
  /google\.|bing\.|baidu\.|yahoo\.|yandex\.|ask\.|duckduckgo\.|naver\.|aol\.|seznam\.|ecosia\./;

const COUNTRY_CODE = process.env.GATSBY_COUNTRY_CODE;

const LOCATIONS_PATHNAMES = {
  'de-DE': /\/standorte(\/)?/,
  'de-AT': /\/standorte(\/)?/,
  'de-CH': /\/standorte(\/)?/,
  'en-GB': /\/locations(\/)?/,
  es: /\/clinicas(\/)?/,
  fr: /\/cabinets-dentaires(\/)?/,
  it: /\/sedi(\/)?/,
  nl: /\/locaties(\/)?/,
  pl: /\/placowki(\/)?/,
  se: /\/platser(\/)?/,
  'pt-PT': /\/clinicas(\/)?/,
};

// Save utms from search query params to local storage.
export const saveUtms = () => {
  const _clearAllUtmsInLocalStorage = utmKeysFromLocalStorage => {
    utmKeysFromLocalStorage.forEach(key => {
      setLocalStorage(key, null);
    });
  };

  const _getUtmKeysFromLocalStorage = () => {
    const utmKeysFromLocalStorage = [];

    Object.keys(localStorage).forEach(curr => {
      if (UTMS.includes(curr)) utmKeysFromLocalStorage.push(curr);
    }, []);

    return utmKeysFromLocalStorage;
  };

  const _isEntryFromOutside = () => {
    return document.referrer !== '';
  };

  const _isPageRefreshed = () => {
    const __utmsSaved = () => {
      return sessionStorage.getItem('utmsSaved') !== null;
    };

    return __utmsSaved() && !_isEntryFromOutside();
  };

  const _updateUtmsInLocalStorage = (utmsFromUrl, utmKeysFromUrl) => {
    utmKeysFromUrl.forEach(key => {
      setLocalStorage(key, utmsFromUrl[key]);
    });
  };

  const _clearOutdatedUtmsInLocalStorage = (utmKeysFromLocalStorage, utmKeysFromUrl) => {
    utmKeysFromLocalStorage.forEach(key => {
      if (!utmKeysFromUrl.includes(key)) setLocalStorage(key, null);
    });
  };

  const _isReferrerSearchEngine = () => {
    return SEARCH_ENGINE_REFERRERS_REGEX.test(document.referrer);
  };

  const _pathnameIsLocations = () => {
    return LOCATIONS_PATHNAMES[COUNTRY_CODE].test(window.location.pathname);
  };

  const _pathnameIsHomepage = () => {
    return window.location.pathname === '/';
  };

  if (isSSR() || _isPageRefreshed()) return;

  const utmsFromUrl = getUtmsFromUrl();
  const utmKeysFromLocalStorage = _getUtmKeysFromLocalStorage();
  const utmsFromUrlEmpty = !isObjectNotEmpty(utmsFromUrl);

  if (utmsFromUrlEmpty) _clearAllUtmsInLocalStorage(utmKeysFromLocalStorage);

  const utmKeysFromUrl = Object.keys(utmsFromUrl);

  _updateUtmsInLocalStorage(utmsFromUrl, utmKeysFromUrl);

  if (!utmsFromUrlEmpty) _clearOutdatedUtmsInLocalStorage(utmKeysFromLocalStorage, utmKeysFromUrl);

  if (!utmKeysFromUrl.includes('utm_source'))
    if (_isEntryFromOutside() && _isReferrerSearchEngine())
      if (_pathnameIsLocations() || _pathnameIsHomepage()) localStorage.setItem('utm_source', 'seo-brand');
      else setLocalStorage('utm_source', 'seo-non-brand');
    else setLocalStorage('utm_source', 'direct');

  try {
    sessionStorage.setItem('utmsSaved', 'true');
  } catch (e) {
    console.error('sessionStorage quota exceeded.', e);
    sessionStorage.clear();
    sessionStorage.setItem('utmsSaved', 'true');
  }
};

export const getUtms = (utmsList = UTMS) => {
  return utmsList.reduce((allUtms, utm) => {
    const utmStorageValue = !isSSR() ? localStorage?.getItem(utm) : '';
    if (utmStorageValue === '') return { ...allUtms };

    return {
      ...allUtms,
      [utm]: utmStorageValue,
    };
  }, {});
};

// get UTM query from utm object from getUtms()
export const getUtmsQuery = (utmParams, url) => {
  const urlContainsQueryPattern = new RegExp(/\?.+=.*/g);

  return Object.entries(utmParams).reduce((acc, [key, value]) => {
    if (value === '') return acc;

    if (acc.length === 0 && !urlContainsQueryPattern.test(url)) return `?${key}=${value}`;
    else return acc + `&${key}=${value}`;
  }, '');
};

export const getUtmsFromUrl = () => {
  const searchParams = new URLSearchParams(location.search);

  return getUtmsFromUrlSearchParams(searchParams);
};
