import React from 'react';
import { createInstance, OptimizelyProvider, setLogger } from '@optimizely/react-sdk';

import getOptimizelyUserId from './getOptimizelyUserId';
import getOptimizelyUserAttributes from './getOptimizelyUserAttributes';
import getOptimizelyDatafile from './getOptimizelyDatafile';

export default ({ element, isServerSide }) => {
  // https://docs.developers.optimizely.com/full-stack/docs/customize-logger-react
  setLogger(null);

  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const optimizely = createInstance({
    datafile: getOptimizelyDatafile(),
    sdkKey: process.env.GATSBY_OPTIMIZELY_SDK,
    datafileOptions: {
      autoUpdate: false,
    },
  });

  const user = {
    id: getOptimizelyUserId(),
    attributes: getOptimizelyUserAttributes(),
  };

  return (
    <OptimizelyProvider optimizely={optimizely} user={user} isServerSide={isServerSide}>
      {element}
    </OptimizelyProvider>
  );
};
