const setSessionStorage = (key, data) => {
  if (!key || !data) throw new Error('The key or data for session storage is not provided');

  const stringifiedData = JSON.stringify(data);
  try {
    sessionStorage.setItem(key, stringifiedData);
  } catch (e) {
    console.error('sessionStorage quota exceeded.', e);
    sessionStorage.clear();
    sessionStorage.setItem(key, stringifiedData);
  }
};

export default setSessionStorage;
