const UTMS = require('../../assets/constants/utms');

/**
 * Returns param:value object by iterating through defined UTM keys inside provided search params class instance
 *
 * @param {Object} searchParams
 *
 * @returns {Object}
 */
function getUtmsFromUrlSearchParams(searchParams) {
  return UTMS.reduce((acc, curr) => {
    const utmContent = searchParams.get(curr);

    if (!utmContent) return acc;

    return {
      ...acc,
      [curr]: utmContent,
    };
  }, {});
}

module.exports = getUtmsFromUrlSearchParams;
