const updateURL = (paramKey, paramValue) => {
  const urlSearchParams = new URLSearchParams(location.search);
  if (typeof paramValue === 'undefined') urlSearchParams.delete(paramKey);
  else urlSearchParams.set(paramKey, paramValue);

  const params = urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '';

  window?.history?.replaceState({}, '', `${location.pathname}${params}`);
};

export default updateURL;
