import { useEffect, useRef, useReducer as useReactReducer } from 'react';

const useReducer = (reducer, initialState, middlewareFunctions = [], afterwareFunctions = []) => {
  const [state, dispatch] = useReactReducer(reducer, initialState);

  const aRef = useRef();

  const dispatchWithMiddleware = action => {
    middlewareFunctions.forEach(middleware => middleware(action, state, reducer));

    aRef.current = action;

    dispatch(action);
  };

  useEffect(() => {
    if (!aRef.current) return;

    afterwareFunctions.forEach(afterware => afterware(aRef.current, state, reducer));

    aRef.current = null;
  }, [afterwareFunctions, state]);

  return [state, dispatchWithMiddleware];
};

export default useReducer;
