import { SET_ACTIVE_STEP, SET_CHANGE_LOCATION, SET_NEXT_STEP, SET_PREV_STEP } from 'components/Booking-new/actions';

import { updateURL } from 'components/Booking-new/utils/helpers';

const filterStepParams = ({ key, value }) => {
  // add params with value greater than 1
  if (value > 1) {
    updateURL(key, value);
    return;
  }

  updateURL(key);
};

const middlewareActions = {
  [SET_NEXT_STEP]: {
    urlParam: 'step',
    getActionData: action => action.payload + 1,
  },
  [SET_CHANGE_LOCATION]: {
    urlParam: 'location',
    // eslint-disable-next-line no-empty-function
    getActionData: () => {},
  },
  [SET_ACTIVE_STEP]: {
    urlParam: 'step',
    getActionData: action => action.payload + 1,
    filter: filterStepParams,
  },
  [SET_PREV_STEP]: {
    urlParam: 'step',
    getActionData: action => action.payload + 1,
    filter: filterStepParams,
  },
};

const URLMiddleware = action => {
  const middlewareAction = middlewareActions[action.type];
  if (typeof middlewareAction !== 'undefined') {
    const key = middlewareAction.urlParam;
    const value = middlewareAction.getActionData(action);

    if (typeof middlewareAction?.filter === 'function') {
      middlewareAction.filter({ key, value });
      return;
    }

    updateURL(key, value);
  }
};

export default URLMiddleware;
