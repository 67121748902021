const initialState = {
  activeStep: 0,
  bookingPrefix: '',
  isError: false,
  userData: {},
  allSteps: [],
  config: {
    errorPage: {},
  },
};

export default initialState;
