// for the Optimizely preview
// todo: rename it to IS_OPTIMIZELY_PREVIEW
export const IS_PREVIEW = process.env.GATSBY_IS_PREVIEW === 'true';

export const LOCATION_URL_ENUM = {
  type: {
    locationCode: 'locationCode',
    state: 'state',
    city: 'city',
    leadGenUrl: '/booking-v2-lead',
  },
  baseUrl: '/booking',
};
