module.exports = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'gclid',
  'ptid',
  'fbclid',
  'ptsrc',
];
