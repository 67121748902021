import { SET_BOOKING, RESET_BOOKING } from 'components/Booking-new/actions';
import isObjectNotEmpty from 'utils/isObjectNotEmpty';
import { getDataFromSessionStorage } from 'components/Booking-new/utils/helpers';
import setSessionStorage from '../../../../utils/setSessionStorage';

const createSessionStorage = (bookingId, state) => {
  setSessionStorage(bookingId, state);
};

const updateSessionStorage = state => {
  const bookingId = state.bookingPrefix;
  const storagedData = getDataFromSessionStorage(bookingId);
  if (!isObjectNotEmpty(storagedData)) return createSessionStorage(bookingId, state);

  const updatedDataToStore = {
    ...storagedData,
    ...state,
  };
  return createSessionStorage(bookingId, updatedDataToStore);
};

const SessionStorageMiddleware = (action, state, reducer) => {
  if (action.type === SET_BOOKING || action.type === RESET_BOOKING)
    return sessionStorage.removeItem(state.bookingPrefix);
  const updatedState = reducer(state, action);
  updateSessionStorage(updatedState);
};

export default SessionStorageMiddleware;
