import React from 'react';
import { initialState, reducer } from 'components/Booking-new/reducers';
import reducerLogger from 'utils/reducerLogger';
import useReducer from 'utils/hooks/useReducer';
import { URLMiddleware, SessionStorageMiddleware } from '../middlewares';
import BookingContext from './BookingContext';

const BookingProvider = ({ children }) => {
  const middlewares = process.env.NODE_ENV === 'development' ? [reducerLogger, URLMiddleware] : [URLMiddleware];
  const [state, dispatch] = useReducer(reducer, initialState, middlewares, [SessionStorageMiddleware]);

  return <BookingContext.Provider value={[state, dispatch]}>{children}</BookingContext.Provider>;
};

export default BookingProvider;
