export function setLocalStorage(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    if (e instanceof DOMException && (e.name === 'QuotaExceededError' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')) {
      console.error('LocalStorage quota exceeded - clearing and resetting item once again ', e);
      localStorage.clear();
      localStorage.setItem(key, value);
    } else {
      console.error('Failed to set item in localStorage.', e);
    }
  }
}
